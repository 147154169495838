@if $password_strength {
  .password-field {
    &__info {
      @media #{$medium-up} {
        #registration & {
          bottom: 45px;
        }
        .checkout__panel & {
          width: 50%;
          margin-top: 6px;
          border: 1px solid $color-black;
          &:before {
            top: 35%;
            #{$rdirection}: 75%;
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);
          }
        }
      }
    }
  }
  #registration_content {
    .profile-info__item {
      margin-top: 15px;
    }
  }
  .profile-password-update {
    &__rules {
      li {
        color: $color-btn-grey;
        display: flex;
      }
    }
    &.registration-page__content {
      #foreground-node & {
        .profile-password-update__button {
          &.disabled {
            opacity: 0.1;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
