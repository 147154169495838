.elc-product-full {
  .elc-vto-foundation {
    &-shade-grid {
      .elc-vto {
        &-custom-shade-picker-item-name {
          display: inline;
          white-space: normal;
        }
        &-tab {
          font-size: 15px;
        }
        &-perfect-shade-label {
          display: flex;
          font-size: 15px;
          justify-content: center;
          white-space: nowrap;
        }
        &-see-pair-product {
          border: 0;
          text-align: $rdirection;
          text-decoration: underline;
        }
        &-see-all-shades,
        &-see-perfect-shades {
          &.elc-button {
            [data-component] & {
              border: 0;
              text-decoration: underline;
            }
          }
        }
      }
    }
    .elc-vto-foundation-shade-picker-wrapper {
      margin-bottom: 10px;
    }
  }
}
