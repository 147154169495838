@import '../theme-bootstrap';

.elc-product-full {
  .elc-product-overview {
    h5 {
      font-size: 20px;
      line-height: get-line-height(20px, 24px);
      @media #{$cr19-xlarge-up} {
        font-size: 24px;
        line-height: get-line-height(24px, 28px);
      }
    }
  }
}
