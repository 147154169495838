.main {
  .content {
    .mobile_mpp {
      #mpp-product-grid {
        .mpp-product {
          .price {
            width: 90%;
            word-wrap: break-word;
          }
        }
      }
    }
    .map_container {
      width: 100%;
      .map_canvas {
        width: 100%;
        .location_info {
          padding: 0px 0px 10px 10px;
        }
      }
    }
  }
}

.trustmark {
  .trustmark_wrapper {
    margin-#{$ldirection}: 10px;
  }
  .trustmark_p {
    @include swap_direction(padding, 0 20px);
    ul {
      list-style: disc;
      margin-#{$ldirection}: 30px;
    }
  }
}

.navigation_container {
  ul {
    li {
      a {
        padding-#{$rdirection}: 21px;
      }
    }
  }
}

.device-mobile {
  .popup-offer-footer-msg {
    font-size: 9px;
  }
}

.offer-promo {
  .link-based-offer-ref-v1 {
    .link-based-offer-mobile-v1 {
      .subheading h2 {
        display: none;
      }
    }
  }
}

.olapic-carousel-list-container .olapic-carousel-partial-container {
  height: 136px;
  line-height: 136px;
  background: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_MOBILE_Thai.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 0;
}

body.ecommerce {
  #top {
    #header {
      .block-template-site-logo-responsive-v1 {
        width: 47%;
      }
    }
  }
}

.clinical-reality__modules--recommended-products {
  .clinical-reality {
    &__recommended-products--carousel {
      top: 38%;
    }
  }
  .ccr-flexslider .flex-viewport {
    max-height: 790px;
  }
}
