.clinical-reality {
  &__results-intro {
    &-button-options {
      .clinical-reality--button {
        @include start-breakpoint($medium-m) {
          max-width: 214px;
        }
      }
    }
  }
}
