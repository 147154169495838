/* -----Power Reviews------ */

#pr-review-display {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'เป็นประโยชน์';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'ไม่มีประโยชน์นัก';
              }
            }
          }
          .pr-rd-flag-review-container {
            width: auto;
            clear: both;
            a {
              padding-left: 0;
            }
          }
          .pr-modal-content {
            .pr-flag-review-btn {
              margin-bottom: 10px;
              font-size: 14px;
            }
          }
        }
      }
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          &:before {
            content: 'กรอง:';
          }
        }
        .pr-rd-review-header-sorts {
          width: auto;
          .pr-rd-sort-group {
            float: right;
          }
        }
        .pr-rd-sort {
          background: url('/media/images/stars/down-arrow.png') no-repeat scroll 100% 2px transparent;
        }
      }
      &.pr-rd-display-desktop {
        .pr-rd-sort-group {
          &:before {
            content: 'จัดเรียงตาม:';
          }
        }
      }
      .pr-rd-sort-group {
        &:before {
          content: 'จัดเรียงตาม:';
        }
      }
      .pr-multiselect {
        .pr-multiselect-button {
          padding: 0 9px 0 0;
          margin: 5px 8px 0 0;
          @media screen and (max-width: 320px) {
            margin: 5px 28px 0 0;
          }
        }
      }
    }
    .pr-review-snapshot-header-intro {
      .pr-headline {
        font-size: 27px;
        line-height: 24px;
      }
    }
  }
}

#pr-write {
  .p-w-r {
    .tag-group {
      .pr-label-control {
        white-space: initial;
      }
    }
  }
}
