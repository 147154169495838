body.loyalty_offer_signup {
  #colorbox {
    &.loyalty-popup-custom-alignment {
      &.horizontal-align {
        &--#{$ldirection} {
          #{$ldirection}: 0 !important;
          #{$rdirection}: unset !important;
          transform: unset;
          &.justify--middle {
            transform: translate(0, -50%);
          }
        }
        &--center {
          #{$ldirection}: 50% !important;
          transform: translateX(-50%);
        }
        &--#{$rdirection} {
          #{$rdirection}: 0 !important;
          #{$ldirection}: unset !important;
          transform: unset;
          &.justify--middle {
            transform: translate(0, -50%);
          }
        }
      }
      &.justify {
        &--top {
          top: 56px !important;
          bottom: unset !important;
        }
        &--middle {
          top: 50% !important;
          transform: translate(-50%, -50%);
        }
        &--bottom {
          bottom: 0 !important;
          top: unset !important;
        }
      }
    }
  }
}

.postal_code_container {
  input {
    padding: 5px 30px;
  }
  label {
    margin-#{$ldirection}: 20px;
  }
  .icon-search {
    position: relative;
    #{$ldirection}: 0.5rem;
    top: -2.1rem;
  }
  .icon-caret-down {
    position: relative;
    top: -2rem;
    float: $rdirection;
    #{$rdirection}: 10px;
  }
}
