.store-locator-mobile-v1 {
  .gm-style-iw-d {
    padding-bottom: 30px;
    overflow: hidden !important;
    max-height: 170px !important;
    margin-right: 30px;
  }
  .doors_results {
    .store-locator_book-appointment {
      padding-top: 15px !important;
      padding-bottom: 12px !important;
      span {
        margin-top: -7px !important;
      }
    }
  }
}

.appt-book {
  .mobile-phone-fieldset {
    .appt-book-mobile-prefix {
      width: 20% !important;
      float: left !important;
    }
    .appt-book-mobile {
      width: 80% !important;
      float: left !important;
    }
  }
  .sign-in-container {
    .social-login__opt-in-label {
      line-height: 1em;
    }
    .social-login__opt-in-checkbox {
      padding-top: 33px;
    }
  }
  .service {
    &-select {
      & .expanded {
        .service-head {
          .icon {
            @include swap_direction(padding, 0 0 30px 0);
          }
        }
      }
    }
    &-details {
      .lesson_description {
        p {
          @include swap_direction(padding, 80px 0 0 0);
        }
      }
    }
  }
  &-section {
    @include swap_direction(margin, 50 0 0 0);
  }
  &-page-header {
    p {
      @include swap_direction(padding !important, 0 0 0 0);
    }
    .appt-book-page-header-content {
      &__subhead {
        width: 95% !important;
        p {
          font-size: 11px !important;
        }
      }
    }
  }
}

#appointments {
  #appointment-book-container {
    .appt-book-page-header-content {
      margin-top: 35px;
      h2,
      h4 {
        width: 65% !important;
      }
    }
  }
}
