.social-login {
  &__email-opt-in {
    label {
      padding-#{$ldirection}: 0;
      @media #{$medium-up} {
        padding-#{$ldirection}: 7px;
      }
      margin-top: 5px;
    }
  }
  .social-login__terms {
    @media #{$medium-up} {
      text-align: #{$ldirection};
      padding: 0;
    }
  }
  .facebook-logo {
    display: none;
  }
}
/* Account Index Page */
#signin {
  #signin-block {
    .social-login {
      .social-login__email-opt-in {
        input[type='checkbox'] {
          @include swap_direction(padding, 7px);
        }
        label {
          margin-#{$ldirection}: 0;
          color: $color-btn-dark-gray;
        }
      }
    }
  }
  .signin_container {
    .social-login {
      .social-login__email-opt-in {
        @media #{$medium-up} {
          display: flex;
        }
        .social-login__opt-in-checkbox {
          @media #{$medium-up} {
            margin-top: 7px;
          }
        }
      }
    }
  }
  .page_checkout_wrapper {
    .social-login {
      .social-login__email-opt-in {
        input[type='checkbox'] {
          @include swap_direction(padding, 7px);
        }
        label {
          padding-#{$ldirection}: 10px;
        }
      }
    }
  }
  #wrapper {
    @media #{$medium-up} {
      height: 500px;
    }
  }
  .main_container {
    .social-login {
      &__email-opt-in {
        input[type='checkbox'] {
          width: auto;
        }
      }
    }
  }
}

#gnav_signin {
  .social-login {
    &__email-opt-in {
      margin-top: 15px;
      label {
        margin: 0;
      }
    }
  }
  overflow-y: scroll;
  height: 450px;
}
/* Gnav loggedIn user */
.elc-user-state-logged-in {
  .social-login.gnav {
    display: none;
  }
}
/* Checkout page styling */
#main.multi {
  .checkout-content {
    .social-login {
      .social-login__title,
      .social-login__info {
        display: none;
      }
      input[type='checkbox'] {
        top: 0;
      }
      .social-login__divider,
      .social-login__terms {
        display: block;
        margin-bottom: 20px;
      }
    }
  }
}

.checkout-content {
  .social-login {
    @media #{$medium-up} {
      max-width: 46%;
      margin: 10px 10px 0 15%;
    }
  }
}
/* Order confirmation page styling */
.confirm {
  #main {
    .social-login {
      input[type='checkbox'] {
        height: 23px;
        width: 23px;
      }
      &__title {
        padding: 0;
      }
      &__container {
        text-align: #{$ldirection};
      }
      &__opt-in-label {
        color: $color-btn-dark-gray;
      }
    }
  }
}
/* Profile preference page styling */
.social-info {
  &__connect {
    .fb-login-button {
      vertical-align: middle;
    }
  }
}
/* Confirmation Page */
#confirm {
  .multi {
    .social-login {
      .social-login__email-opt-in {
        label {
          color: $color-btn-dark-gray;
        }
      }
    }
  }
}
