$address-border: #999999;
$address-background-color: #eeeeee;
$label-error: #ef6ea8;
$disabled-color: #dddddd;

.address-autofill {
  z-index: 999;
  border: 1px solid $address-border;
  background-color: $address-background-color;
  width: 97%;
  height: 200px;
  overflow: scroll;
  font-size: 12px;
  li {
    line-height: 20px;
  }
  span {
    margin: 5px;
  }
}

label.error {
  color: $label-error;
}

ul.menu {
  .parent-auto-ship {
    display: none;
  }
}

#main.multi {
  fieldset.fs {
    input[type='password'] {
      padding: 4px 0;
      margin: 8px 5px 10px 0;
      text-indent: 8px;
      height: 40px;
      font-size: 15px;
      width: 100%;
    }
  }
}

textarea.error {
  border: 1px solid $label-error;
}

.checkout-btn {
  .button.disabled {
    background: $disabled-color;
    cursor: not-allowed;
  }
}

.footer-kbank-logo,
.footer-dbd-logo {
  float: #{$ldirection};
  width: 41%;
}

.footer-dbd-logo img {
  width: 99px;
  height: 45px;
}

.footer-kbank-logo {
  margin-#{$ldirection}: 38px;
}

.device-mobile {
  .content_container {
    .outer-wrap {
      @include swap_direction(padding, 0 0 0);
    }
    .single-video {
      iframe {
        width: 100%;
      }
      &-v1 {
        border-bottom: 0 solid $color-white;
        .image {
          &-title {
            display: none;
          }
          &-placeholder {
            img {
              display: block;
              width: 100%;
              max-width: auto;
            }
          }
        }
      }
    }
  }
  #footer {
    .footer-bottom {
      .field-mobile-menu {
        ul.menu {
          li.item-5 {
            clear: both;
          }
        }
      }
    }
  }
  .zdc {
    &_popup {
      margin-#{$ldirection}: 0 !important;
      width: 100% !important;
      .zdc__popup {
        &--content {
          padding-top: 50px;
        }
      }
    }
    &__bg {
      &--display {
        max-width: 100% !important;
      }
    }
    &__description,
    &__dollar--buttons {
      .button {
        line-height: 2.5;
      }
    }
  }
}
